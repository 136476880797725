<template>
<div>
    <v-container fluid class="pa-0">
        <v-img :src="require('@/assets/img/'+banner+'.png')" />
    </v-container>
    <v-container>
        <div v-if="pago.correcto">
            <p class="text-center azul font-25 mt-5 font-weight-bold">
                ¡Felicidades! Ya estás protegid@!
            </p>
            <p class="text-center azul font-22">
                Te enviamos un mail con tu Kit de Bienvenida, las Condiciones Generales del producto así como otros archivos adjuntos <br>
                importantes que debes leer para conocer el alcance de cada uno de los servicios con los que cuentas.
            </p>
            <p class="text-center azul font-22">
                Te recordamos que para mantener activo tu programa de Protección, haremos un cargo recurrente de forma mensual a la tarjeta que registraste.
            </p>
            <p class="text-center azul font-21">
                ¡Agradecemos tu confianza y te felicitamos por tomar la decisión de protegerte a ti y a tu familia!
            </p>
        </div>
        <div v-if="pago.error">
            <p class="text-center font-25 mt-5 azul font-weight-bold">
                No pudimos procesar tu pago
            </p>
            <p class="text-center font-23 mt-5 azul">
                Comunicate con tu banco y verifica que no tengas problemas con tu tarjeta, no te quedes sin adquirir los productos que Chedraui tienen para ti y tu familia
            </p>
        </div>
        <div class="mt-5 text-center">
            <v-btn color="#0c55a6" class="btnNoMayuscula" dark to="/">Ir al inicio</v-btn>
        </div>
    </v-container>
    <Footer />
</div>
</template>

<script>
import Footer from "@/components/Footer.vue";
export default {
    name: "Pago",
    components: {
        Footer
    },
    data() {
        return {
            pago: {
                correcto: this.$route.query.reference,
                error: this.$route.query.result
            }
        }
    },
    computed: {
        banner() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return 'BannerMovil';
                case 'sm':
                    return 'BannerMovil';
                case 'md':
                    return 'Banner';
                case 'lg':
                    return 'Banner';
                case 'xl':
                    return 'Banner';
            }
        }
    }
}
</script>
